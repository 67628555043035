<template>
    <div class="page">
        <NavigationBar title="包赔案例" v-show="is_navbar === 'true'"></NavigationBar>
        <div class="content"></div>
        <div class="content-title">
            <div class="title"></div>
            <div class="content-item">
                <div class="content-items step1">交易监控</div>
                <div class="content-items step2">保障服务</div>
                <div class="content-items step3">资金无忧</div>
                <div class="content-items step4">平台兜底</div>
            </div>
            <div class="content-text">
                <div class="text">客服全程介入</div>
                <div class="text">即时包赔升级</div>
                <div class="text">多方维护权益</div>
                <div class="text">十年信誉保障</div>
            </div>
        </div>
        <div class="cases-title"></div>
        <div class="recoveryCaseContainer" v-show="!defaultPage">
            <van-list v-model="loading" :finished="finished" @load="onLoad">
                <div class="cases-content" v-for="(item, index) in casesList" :key="item.id">
                    <div class="left">
                        <img :src="item.pic" alt="" class="pic">
                        <img src="../../../static/images/cases/hours.png" alt="" class="tips">
                    </div>
                    <div class="right">
                        <div class="title">{{ item.title }}</div>
                        <div class="casePrice">赔付金额：<div class="count">{{ item.claim_amount }}元</div>
                        </div>
                        <div class="time">{{ item.claim_at }}</div>
                    </div>
                </div>
            </van-list>
        </div>
        <div class="defaultPageContainer" v-show="defaultPage">
            <div class="imgContainer">
                <img src="@/static/images/defaultPage@2x.png" alt="" class="defaultPageImg" />
                <div class="defaultPageText">暂时没有案例哦~</div>
            </div>
        </div>
    </div>
</template>

<script>
import { NavigationBar } from "@/components";
import { getMaterialList } from '@/api/security';
export default {
    name: "CompensationCases",
    data() {
        return {
            casesList: [],
            is_navbar: "true",
            is_share_navbar: "true",
            finished: false,
            loading: false,
            page: 1,
            page_size: 10,
            defaultPage: false
        };
    },
    components: {
        NavigationBar,
    },
    mounted() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.is_navbar = this.$route.query.is_navbar || "true";
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },
        onLoad() {
            let config = {
                type: 3,
                page: this.page,
                page_size: this.page_size,
            };
            this.requestGetMaterialList(config);
        },
        async requestGetMaterialList(config) {
            this.loading = true;
            try {
                const result = await getMaterialList(config);
                if (result.code === 20000 || result.status_code === 1000) {
                    this.casesList = [...this.casesList, ...result.data.data];
                    let newobj = {};
                    // 去重
                    this.casesList = this.casesList.reduce((preVal, curVal) => {
                        newobj[curVal.id] ? "" : (newobj[curVal.id] = preVal.push(curVal));
                        return preVal;
                    }, []);
                    // 没有数据了
                    if (result.data.data.length < this.page_size) {
                        this.finished = true;
                    } else {
                        this.page += 1;
                        this.loading = false;
                    }
                    if (this.page === 1 && result.data.data.length === 0) {
                        this.defaultPage = true;
                    } else {
                        this.defaultPage = false;
                    }
                    // 缺省页
                } else {
                    this.$toast(result.message);
                }
            } catch (error) {
                this.$toast("请求失败");
            }
        },
    }
}
</script>

<style lang="less" scoped>
.page {
    background: #F6F7F8;
    padding-bottom: 8px;
}

.content {
    min-width: 100vw;
    height: 200px;
    background: url('../../../static/images/cases/baopeiBg.png') no-repeat top center;
    background-size: 100%;

}

.content-title {
    margin: 0 auto;
    margin-top: -27px;
    padding: 23px 15px 20px 18px;
    width: 355px;
    height: 160px;
    background: #FFFFFF;
    border-radius: 12px;
    box-sizing: border-box;

    .title {
        width: 322px;
        height: 14px;
        background: #fff url('../../../static/images/cases/weiquan1.png') no-repeat top center;
        background-size: 100%;
    }

    .content-item {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 21px;
        width: 294px;
        height: 60px;

        .content-items {
            width: 60px;
            height: 60px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
            line-height: 115px;
            text-align: center;
            font-style: normal;
            box-sizing: border-box;

            &.step1 {
                background: url('../../../static/images/cases/baopei1.png') no-repeat top center;
                background-size: 100%;
            }

            &.step2 {
                background: url('../../../static/images/cases/baopei2.png') no-repeat top center;
                background-size: 100%;
            }

            &.step3 {
                background: url('../../../static/images/cases/baopei3.png') no-repeat top center;
                background-size: 100%;
            }

            &.step4 {
                background: url('../../../static/images/cases/baopei4.png') no-repeat top center;
                background-size: 100%;
            }
        }
    }

    .content-text {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 10px;
        width: 294px;
        height: 14px;

        .text {
            width: 60px;
            height: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 10px;
            color: #333333;
            line-height: 14px;
            text-align: center;
            font-style: normal;
        }
    }
}

.cases-title {
    margin: 0 auto;
    margin-top: 23px;
    width: 210px;
    height: 14px;
    background: url('../../../static/images/cases/cases1.png') no-repeat top center;
    background-size: 100%;
}

.cases-content {
    display: flex;
    margin: 13px auto;
    padding: 8px;
    width: 355px;
    height: 96px;
    background: #FFFFFF;
    border-radius: 8px;
    box-sizing: border-box;

    .left {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 8px;

        .pic {
            width: 80px;
            height: 80px;
        }

        .tips {
            position: absolute;
            top: 8px;
            left: -8px;
            width: 88px;
            height: 27px;
        }
    }

    .right {
        margin-left: 12px;

        .title {
            width: 210px;
            height: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #222222;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .casePrice {
            display: flex;
            margin-top: 22px;
            width: 210px;
            height: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 13px;
            color: #999999;
            line-height: 18px;
            text-align: left;
            font-style: normal;

            .count {
                color: #FF0000;
            }
        }

        .time {
            margin-top: 2px;
            width: 210px;
            height: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 13px;
            color: #999999;
            line-height: 18px;
            text-align: left;
            font-style: normal;
        }
    }
}
</style>